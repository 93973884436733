import { memo } from 'react';
import {
  getClient,
  getFooter,
  getHeader,
  getLocales
} from '@services/contentful/api';
import contentfulClient, {
  contentfulPreviewClient
} from '@services/contentful/client';
import Landing from '@components/landing';
import PageHead from '@components/pageHead';
import { PageContentTypes } from '@constants/index';

const Home = ({ pageData, headerData, footerData, locales }) => {
  const seoMetadata = pageData?.seoMetadata?.fields;

  return (
    <>
      <PageHead {...seoMetadata} />
      <Landing
        pageData={pageData}
        headerData={headerData}
        footerData={footerData}
        locales={locales}
      />
    </>
  );
};

export async function getStaticProps({ preview }) {
  const client = preview ? contentfulPreviewClient : contentfulClient;

  const res = await client.getEntries({
    content_type: PageContentTypes.LandingPage,
    'fields.slug': 'en',
    // 'metadata.tags.sys.id[in]': 'energyHub',
    include: 8
  });

  const headerData = await getHeader({ preview });

  const footerData = await getFooter({ preview });
  const locales = await getLocales(preview);

  if (!res?.items?.[0]?.fields) {
    return {
      notFound: true,
      revalidate: 10
    };
  }

  return {
    props: {
      pageData: res?.items?.[0]?.fields || {},
      ...headerData,
      ...footerData,
      locales
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every mentioned revalidation timeout
    revalidate: process.env.NEXT_PUBLIC_REVALIDATION_TIME
  };
}

export default memo(Home);
